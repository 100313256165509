<template>
  <div>
    <div class="text-center">
      <v-row>
        <v-col>
          <v-progress-circular
            :rotate="360"
            :size="120"
            :width="15"
            :value="salePercent"
            color="primary"
          >
            {{ Intl.NumberFormat().format(salePercent) }}%
          </v-progress-circular>
          <h1 class="main-title">
            {{ Intl.NumberFormat().format(targetSaleOfFactory) }}
          </h1>
          <h3 class="v-title">Target sale 2021</h3>
        </v-col>
        <v-col>
          <v-progress-circular
            :rotate="360"
            :size="120"
            :width="15"
            :value="userPercent"
            color="teal"
          >
            {{ Intl.NumberFormat().format(userPercent) }}%
          </v-progress-circular>
          <h1 class="main-title">
            {{ Intl.NumberFormat().format(targetCustomerOfFactory) }}
          </h1>
          <h3 class="v-title">User Target 2021</h3>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "targetSaleOfFactory",
    "salePercent",
    "targetCustomerOfFactory",
    "userPercent",
  ],
};
</script>

<style>
.main-color {
  color: #00c1d2 !important;
}
.v-application .primary--text {
  color: #00c1d2 !important;
  caret-color: #00c1d2 !important;
}
.main-icon {
  color: #00c1d2 !important;
  font-size: 2.5rem !important;
}
.v-active {
  font-size: 14px;
  color: #00c1d2;
  font-weight: 300;
  margin-right: 8px;
}
.none-active {
  font-size: 14px;
  color: #e74c3c;
  font-weight: 300;
}
.v-title {
  font-weight: 600;
  color: #303a49;
  font-size: 1.1rem;
}
.main-title {
  color: #303a49;
  font-size: 1.3rem;
}
</style>
