<template>
  <div>
    <div class="chart">
      <div class="columns">
        <div class="column">
          <apexchart
            ref="realtimeChart"
            type="bar"
            height="460"
            :options="dataSet"
            :series="packageskey"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dashboard from "@/mixins/dashboard";
export default {
  mixins: [dashboard],
};
</script>

<style>
.apexcharts-canvas {
  font-size: 30px;
}
.table {
  font-family: "Roboto", "NotoSanLao" !important;
  width: 100%;
  overflow: scroll;
  position: relative;
  border-collapse: collapse;
}
.wrap_table {
  overflow-x: auto;
  width: 100%;
}
table thead {
  position: sticky;
  top: 0;
  border-top: 2px solid #dbdbdb;
}
.chart {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
.table td,
.table th {
  vertical-align: middle;
  text-align: center;
}
#chart {
  max-width: 760px;
  margin: 35px auto;
  opacity: 0.9;
}
</style>
