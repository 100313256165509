<template>
  <div>
    <v-row>
      <v-col>
        <v-menu
          v-model="start_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="sale_date"
              label="ເລີ່ມວັນທີ"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker v-model="sale_date" type="month"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="d-flex">
        <v-select
          v-model="selectedPayment"
          :items="payment_Types"
          label="ປະເພດ"
          item-text="name"
          item-value="value"
          dense
          outlined
        ></v-select>
      </v-col>
      <v-col>
        <h3 class="v-title">
          ຈຳນວນເງິນ {{ Intl.NumberFormat().format(sumTotalPrice) }}
        </h3>
      </v-col>
      <v-col>
        <h3 class="v-title">
          ຈຳນວນບິນ
          {{ Intl.NumberFormat().format(countCustomerInvoice) }}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <div>
        <apexchart
          type="bar"
          height="400"
          width="1100"
          :options="dates"
          :series="series"
        ></apexchart>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sale_date: new Date().toISOString().substr(0, 7),
      start_menu: false,
      old_date: "",
      sumTotalPrice: 0,
      countCustomerInvoice: 0,
      selectedPayment: "bill",
      dataSet: [],
      payment_Types: [
        {
          value: "bill",
          name: "ຈຳນວນບິນ",
        },
        {
          value: "volume",
          name: "ຈຳນວນເງິນ",
        },
      ],
      series: [
        {
          name: "Amount",
          data: [],
        },
      ],
      dates: {
        noData: {
          text: "Loading ...",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          // style: {
          //   color: "#00c1d2",
          //   fontSize: "14px",
          // },
        },
        title: {
          text: "Daily Sale",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily:
              "NotoSanLao, Google Sans, arial, serif, sans-serif !important",
            color: "#263238",
          },
        },
        labels: [],
        colors: ["#00c1d2", "#FF9933", "#FF5733"],

        xaxis: {
          labels: {
            show: true,
            rotate: -45,
            align: "center",
            style: {
              colors: [],
              fontSize: "16px",
              fontWeight: 400,
            },
          },
          title: {
            text: "Date",
            align: "left",
            margin: 100,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              color: "#263238",
            },
          },
        },
        yaxis: {
          title: {
            text: "Average",
          },
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: [],
              fontSize: "14px",
              fontFamily:
                "NotoSanLao, Google Sans, arial, serif, sans-serif !important",
              fontWeight: 400,
            },
          },
        },
      },
    };
  },
  methods: {
    async fetchSaleData() {
      const dataSet = {
        data: [],
        sumtotal: [],
        labels: [],
      };
      this.dataSet = [];
      this.loading = true;
      await this.$axios
        .post("/report/sales/on/dashboard", {
          month: this.sale_date,
        })
        .then((res) => {
            this.sumTotalPrice = res.data.sumTotalPrice;
            this.countCustomerInvoice = res.data.countCustomerInvoice;
            this.dataSet = res.data.allDates;
            res.data.allDates.map((item) => {
              dataSet.data.push(item.countCustomerInvoice);
              dataSet.sumtotal.push(item.sumTotal);
              dataSet.labels.push(item.number);
            });
            this.loading = false;
            this.start_menu = false;
        })
        .catch(() => {
          this.start_menu = false;
          this.loading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
      this.getDataSet();
      // for (var i = 0; i < dataSet.data.lenght; i++) {
      //   this.series[i].data = dataSet.data;
      //   this.series[i].data = dataSet.sumtotal;
      // }
      if (this.selectedPayment == "bill") {
        this.series[0].data = dataSet.data;
      } else if (this.selectedPayment == "volume") {
        this.series[0].data = dataSet.sumtotal;
      }
      this.dates.labels = dataSet.labels;
      window.dispatchEvent(new Event("resize"));
    },
    getDataSet() {
      const dataSet = {
        data: [],
        sumtotal: [],
        labels: [],
      };
      this.dataSet.map((item) => {
        dataSet.data.push(item.countCustomerInvoice);
        dataSet.sumtotal.push(item.sumTotal);
        dataSet.labels.push(item.number);
      });
      if (this.selectedPayment == "bill") {
        this.series[0].data = dataSet.data;
      } else if (this.selectedPayment == "volume") {
        this.series[0].data = dataSet.sumtotal;
      }
      this.dates.labels = dataSet.labels;
      window.dispatchEvent(new Event("resize"));
    },
    FilterDate() {
      if (this.sale_date !== "") {
        if (this.old_date !== "") {
          if (this.old_date !== this.sale_date) {
            this.old_date = this.sale_date;
            this.fetchSaleData();
          }
        } else {
          if (this.sale_date !== "") {
            this.fetchSaleData();
          }
          this.old_date = this.sale_date;
        }
      } else {
        this.old_date = "";
      }
    },
  },
  watch: {
    selectedPayment: function () {
      this.fetchSaleData();
    },
    sale_date: function () {
      this.FilterDate();
    },
  },
  created() {
    // this.fetchSaleData();
    // this.getDataSet();
  },
};
</script>

<style>
</style>
