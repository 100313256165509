<template>
  <div>
    <v-menu
      v-model="start_menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="register_date"
          label="ເລີ່ມວັນທີ"
          readonly
          outlined
          v-bind="attrs"
          v-on="on"
          dense
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="register_date"
        type="month"
        @input="FilterDate()"
        @change="FilterDate()"
      ></v-date-picker>
    </v-menu>
    <div class="chart desktop" id="chart">
      <apexchart
        ref="realtimeChart"
        type="bar"
        height="360"
        :options="dates"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      register_date: new Date().toISOString().substr(0, 7),
      old_date: "",
      start_menu: false,
      customers: [],
      series: [
        {
          data: [],
        },
      ],
      dates: {
        noData: {
          text: "Loading ...",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#00c1d2",
            fontSize: "14px",
          },
        },
        title: {
          text: "User Register",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily:
              "NotoSanLao, Google Sans, arial, serif, sans-serif !important",
            color: "#263238",
          },
        },
        responsive: [
          {
            breakpoint: 720,
            provinces: {
              style: {
                fontSize: "44px",
                fontWeight: "bold",
                color: "#263238",
              },
              chart: {
                width: "100%",
              },
            },
          },
        ],
        labels: [],
        colors: ["#00c1d2", "#00c1d2"],
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },

        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -45,
            align: "center",
            style: {
              colors: [],
              fontSize: "12px",
              fontWeight: 400,
            },
          },
          title: {
            text: "Daily",
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              color: "#263238",
            },
          },
        },
        yaxis: {
          title: {
            text: "Amount Users",
          },
        },
      },
    };
  },
  methods: {
    async fetchCustomerRegister() {
      const dataSet = {
        data: [],
        labels: [],
      };
      this.loading = true;
      await this.$axios
        .post("/report/user/register/on/dashboard", {
          month: this.register_date,
        })
        .then((res) => {
            res.data.allDates.map((item) => {
              dataSet.data.push(item.countCustomer);
              dataSet.labels.push(item.number);
            });
            this.loading = false;
            this.start_menu = false;
        })
        .catch(() => {
          this.start_menu = false;
          this.loading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
      this.series[0].data = dataSet.data;
      this.dates.labels = dataSet.labels;
      window.dispatchEvent(new Event("resize"));
    },

    FilterDate() {
      if (this.register_date !== "") {
        if (this.old_date !== "") {
          if (this.old_date !== this.register_date) {
            this.old_date = this.register_date;
            this.fetchCustomerRegister();
          }
        } else {
          if (this.register_date !== "") {
            this.fetchCustomerRegister();
          }
          this.old_date = this.register_date;
        }
      } else {
        this.old_date = "";
      }
    },
  },
  watch: {
    // register_date: function (value) {
    //   (value);
    //   (this.old_date);
    //   if (value !== this.old_date) {
    //     this.fetchCustomerRegister();
    //   }
    // },
  },

  created() {
    this.fetchCustomerRegister();
  },
};
</script>

<style>
.apexcharts-canvas {
  font-size: 30px;
}
</style>
