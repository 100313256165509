<template>
  <div>
    <v-card>
      <v-card-text>
        <v-overlay :value="loading">
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          ></v-progress-circular>
        </v-overlay>
        <v-row>
          <v-col cols="4">
            <div class="count-user">
              <div class="left-item">
                <v-icon class="main-icon">mdi-account</v-icon>
              </div>
              <div class="right-item">
                <h1 class="main-title">
                  {{ Intl.NumberFormat().format(sumCustomerUnActive) }}
                </h1>
                <h3 class="v-title">ລູກຄ້າໃໝ່</h3>
              </div>
            </div>
            <div class="count-user">
              <div class="left-item">
                <v-icon class="main-icon">mdi-account-group</v-icon>
              </div>
              <div class="right-item">
                <h1 class="main-title">
                  {{ Intl.NumberFormat().format(sumCustomers) }}
                </h1>
                <h3 class="v-title">
                  ລູກຄ້າລວມ
                  <span class="v-active"
                    >{{
                      Intl.NumberFormat().format(sumCustomerActiveByPercent)
                    }}
                    % ເຄື່ອນໄຫວ </span
                  ><span class="none-active">
                    {{
                      Intl.NumberFormat().format(sumCustomerUnActiveByPercent)
                    }}
                    % ບໍ່ເຄື່ອນໄຫວ</span
                  >
                </h3>
              </div>
            </div>
          </v-col>
          <v-col cols="8">
            <h3 class="text-center">ປະເພດລູກຄ້າ</h3>
            <div class="chart">
              <div class="columns">
                <div class="column">
                  <apexchart
                    width="650"
                    type="pie"
                    :options="customercategory"
                    :series="customerselected"
                  ></apexchart>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-divider class="v-divider"></v-divider>
        <!---=============== Customer Register-->
        <v-row>
          <v-col cols="6">
            <!--
            <h3 class="v-title">User Register</h3>
            <br />-->
            <customerChart />
          </v-col>
          <!---=============== Customer selected packaage-->
          <v-col cols="6">
            <packageChart />
          </v-col>
        </v-row>
        <v-divider class="v-divider"></v-divider>

        <!---=============== Top Sale chart-->

        <v-row>
          <v-col cols="6">
            <div class="count-user">
              <div class="left-item">
                <v-icon class="main-icon">mdi-chart-bar</v-icon>
              </div>
              <div class="right-item">
                <h1 class="main-title">
                  {{ Intl.NumberFormat().format(sumTotalIncome) }}
                </h1>
                <h3 class="v-title">ລາຍໄດ້ລວມສະສົມ</h3>
              </div>
            </div>
            <div class="count-user">
              <div class="left-item">
                <v-icon class="main-icon">mdi-cash-multiple</v-icon>
              </div>
              <div class="right-item">
                <h1 class="main-title">
                  {{ Intl.NumberFormat().format(sumTotalProfit) }}
                </h1>
                <h3 class="v-title">
                  ກຳໄລສະສົມ
                  <span class="v-active"
                    ><span v-if="sumTotalProfitByPercent > 0">+ </span
                    >{{ Intl.NumberFormat().format(sumTotalProfitByPercent) }} %
                  </span>
                </h3>
              </div>
            </div>
          </v-col>

          <v-col cols="6">
            <h3 class="v-title">ກຳໄລ</h3>
            <br />
            <v-row>
              <v-col>
                <v-menu
                  v-model="profit_start_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="profit_start_date"
                      label="ເລີ່ມວັນທີ"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="profit_start_date"
                    @input="fetchProfit()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  v-model="profit_end_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="profit_end_date"
                      label="ຫາວັນທີ"
                      readonly
                      outlined
                      small
                      v-bind="attrs"
                      v-on="on"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="profit_end_date"
                    @input="fetchProfit()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <div class="count-user">
                  <div class="left-item">
                    <v-icon class="main-icon">mdi-cash</v-icon>
                  </div>
                  <div class="right-item">
                    <h1 class="main-title">
                      {{ Intl.NumberFormat().format(sumTotalIncomeBydate) }}
                    </h1>
                    <h3 class="v-title">ລາຍໄດ້</h3>
                  </div>
                </div>
                <div class="count-user">
                  <div class="left-item">
                    <v-icon class="main-icon">mdi-cash-plus</v-icon>
                  </div>
                  <div class="right-item">
                    <h1 class="main-title">
                      {{ Intl.NumberFormat().format(sumTotalProfitBydate) }}
                    </h1>
                    <h3 class="v-title">
                      ກຳໄລ
                      <span class="v-active">
                        <span v-if="sumTotalProfitByPercent > 0">+ </span
                        >{{
                          Intl.NumberFormat().format(sumTotalProfitByPercent)
                        }}
                        %
                      </span>
                    </h3>
                  </div>
                </div>
              </v-col>
              <v-col>
                <div class="count-user">
                  <div class="left-item">
                    <v-icon class="main-icon">mdi-cup-water</v-icon>
                  </div>
                  <div class="right-item">
                    <h1 class="main-title">
                      {{ Intl.NumberFormat().format(sumTotalBottle) }}
                    </h1>
                    <h3 class="v-title">ຕຸກ</h3>
                  </div>
                </div>
                <div class="count-user">
                  <div class="left-item">
                    <v-icon class="main-icon">mdi-dishwasher</v-icon>
                  </div>
                  <div class="right-item">
                    <h1 class="main-title">
                      {{ Intl.NumberFormat().format(countCustomerInvoice) }}
                    </h1>
                    <h3 class="v-title">ຈຳນວນບິລ</h3>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h3 class="v-title">ຍອດຂາຍສະເລ່ຍຕໍ່ອາທິດ</h3>
            <br />
            <topChartSale />
          </v-col>
        </v-row>

        <!---===============-->
        <v-row>
          <v-col cols="6">
            <div align="center">
              <h3 class="main-title">ຍອດເຕີບໂຕພາຍໃນເດືອນ</h3>
              <h1 class="main-title main-color">
                {{ Intl.NumberFormat().format(sumTotalProfitByCurrentMonth) }}%
              </h1>
              <h3 class="v-title">
                ເປົ້າໝາຍການເຕີບໂຕສະເລ່ຍຕໍ່ເດືອນ
                {{
                  Intl.NumberFormat().format(sumTotalTargetGrowByCurrentMonth)
                }}%
              </h3>
            </div>
            <topChartMonthly />
          </v-col>
          <v-col cols="6">
            <div align="center">
              <div align="center">
                <h3 class="main-title mb-16">ເປົ້າໝາຍ</h3>
              </div>
              <targetSale
                :targetSaleOfFactory="targetSaleOfFactory"
                :salePercent="salePercent"
                :userPercent="userPercent"
                :targetCustomerOfFactory="targetCustomerOfFactory"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import customerChart from "@views/dashboard/customerChart";
import packageChart from "@views/dashboard/packagechart";
import topChartSale from "@views/dashboard/topChartSale";
import topChartMonthly from "@views/dashboard/topChartMonthly";
import targetSale from "@views/dashboard/targetSale";
import dashboard from "@/mixins/dashboard";
export default {
  mixins: [dashboard],
  components: {
    customerChart,
    packageChart,
    topChartSale,
    topChartMonthly,
    targetSale,
  },
  metaInfo() {
    return {
      title: `Dashboard - Vari`,
    }
  },
  created() {
    this.fetchProfit();
    // this.fetchData();
  }
};
</script>

<style>
.main-color {
  color: #00c1d2 !important;
}
.main-icon {
  color: #00c1d2 !important;
  font-size: 2.5rem !important;
}
.v-active {
  font-size: 14px;
  color: #00c1d2;
  font-weight: 300;
  margin-right: 8px;
}
.none-active {
  font-size: 14px;
  color: #e74c3c;
  font-weight: 300;
}
.v-title {
  font-weight: 600;
  color: #303a49;
  font-size: 1.1rem;
}
.main-title {
  color: #303a49;
  font-size: 1.3rem;
}

.count-user {
  display: flex;
  width: 100%;
  padding-bottom: 2rem;
}
.right-item {
  display: inline-block;
  width: 90%;
}
.left-item {
  margin: auto;
  margin-right: 18px;
}
.v-divider {
  color: #303a49;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.v-progress-linear {
  border-radius: 12px;
  margin: 0;
  background-color: #000000 !important;
}
.progress-bar-txt {
  position: relative;
  font-size: 12px;
  left: 6px;
  z-index: 1;
}
.v-application .primary {
  background-color: #00c1d2 !important;
  border-color: #00c1d2 !important;
}
.v-progress-linear__content {
  color: white !important;
}
.apexcharts-legend-series {
  margin-top: -5px !important;
  margin-bottom: -5px !important;
  font-family: "Roboto", "NotoSanLao" !important;
}
.apexcharts-legend-series span {
  font-size: 14px !important;
  font-family: "Roboto", "NotoSanLao" !important;
}
</style>
