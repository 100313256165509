<template>
  <div>
    <div class="chart desktop" id="chart">
      <apexchart
        ref="realtimeChart"
        type="bar"
        height="250"
        :options="monthly"
        :series="salevalue"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import dashboard from "@/mixins/dashboard";
export default {
  mixins: [dashboard],
};
</script>

<style>
.apexcharts-canvas {
  font-size: 30px;
}
.table {
  font-family: "Roboto", "NotoSanLao" !important;
  width: 100%;
  overflow: scroll;
  position: relative;
  border-collapse: collapse;
}
.wrap_table {
  overflow-x: auto;
  width: 100%;
}
table thead {
  position: sticky;
  top: 0;
  border-top: 2px solid #dbdbdb;
}
.chart {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
.table td,
.table th {
  vertical-align: middle;
  text-align: center;
}
#chart {
  max-width: 760px;
  margin: 35px auto;
  opacity: 0.9;
}
@media (max-width: 720px) {
  .desktop {
    display: none;
  }
}
@media (min-width: 719px) {
  .mobile {
    display: none;
  }
}
</style>
